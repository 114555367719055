.soliBody{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10000;
    background: rgba(0,0,0,0.7);
}
.solicutud{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10002;
    top: 210px;
    left: 10%;
    width: 80%;
    height: auto;
    padding: 50px 10px;
    background: var(--fourth-color);
    border-radius: 27px;
    gap: 30px;
    p{
        margin: 10px;
        font-size: 60px;
        line-height: 72px;
        color: var(--primary-color);     
        gap: 30px;       
    }
    .switch-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px; 
        justify-content: center;
        
        /* Aumenta el espacio entre los checkboxes */
      }
      }
      .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        
      }
      
      .checkbox-wrapper input[type="checkbox"] {
        appearance: none;
        width: 50px;
        height: 50px;
        border: 2px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 10px; 
        align-items: center;
        justify-content: center;
      }
      
      .checkbox-wrapper input[type="checkbox"]:checked {
        background-color: #d0021b;
        border-color: #d0021b;
        align-items: center;
        justify-content: center;
      }
      
      .checkbox-wrapper label {
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }


@media (max-width: 768px) {
    .solicutud{
        flex-direction: column;
    }

    @media (max-width: 480px) {
        .soliBody {
          padding: 10px;
        }
        .switch-tab {
          flex-direction: column;
          align-items: center;
        }
        .switch-tab span {
          margin: 10px 0;
        }
      }
      
}