@import '../../../assets/styles/brand-colors';
@import '../../../assets/styles/font-styles';

.img-logo-mobile-menu-general {
    width: 193px;
    height: 134px;
    left: 14px;
    top: -7px;
    background: url('../../../assets/images/logomobile.png');
}

.img-logo-home {
    width: 41.9px;
    height: 35.67px;
    margin-top: 20px;
    margin-left: 80px;
    background: url('../../../assets/images/home.png');
}

.img-logo-black {
    width: 41.9px;
    height: 35.67px;
    margin-top: 20px;
    margin-left: 80px;
    background: url('../../../assets/images/logo.png');
}

.logo-black {
    width: 41.9px;
    height: 35.67px;
    margin-top: 20px;
    margin-left: 80px;
    background: url('../../../assets/images/logo-black.png');
}

.img-logo-afianzando {
    width: 70px;
    height: 49px;
    margin-top: 12px;
    background: url('../../../assets/images/logo_afianzando.png');
}
.img-logo-american {
    width: 70px;
    height: 49px;
    margin-top: 12px;
    background: url('../../../assets/images/logo_american.png');
}
.img-logo-credimoto {
    width: 87px;
height: 60px;
margin-top: 12px;
    background: url('../../../assets/images/logo_credimoto.png');
}
.img-logo-sarmiento {
    width: 148px;
    height: 80px;
    background: url('../../../assets/images/logo_sarmiento.png');
}
.logo_black {
    width: 180px;
    height: 50px;
}

.img-logo-black-sarmiento {
    max-width: 20%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .img-logo-black-sarmiento{
        max-width: 80%;
        margin: 0 auto;
    }
}

.v-line {
    border-left: solid var(--fourth-color);
    margin: 10px;
}

.btn-close-session {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: var(--fourth-color);
}

.name-user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    padding: 0 !important;
    align-items: center;
    text-align: right;
    letter-spacing: -0.015em;
    color: var(--fourth-color);
}

.menu-init {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--fourth-color);
}

.menu-language {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    padding: 0 !important;
    color: var(--fourth-color);
}