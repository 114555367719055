.mansaje{
    .mansajeBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;

        h4{
            font-size: 45px;
            line-height: 111.5%;
            color: var(--fourth-color);  
            margin: 10px 0px 5px;  
        }
        h1{
            font-family: 'Bebas Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 200px;
            line-height: 111.5%;
            color: var(--fourth-color); 
            margin: 5px 0px 10px;   
        }
        .solicitud {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .switch-tab {
            display: flex;
            justify-content: center;

         .checkbox{
                width: 100 px;
                
              }
          }
    
    }
    @media (max-width: 768px) {
        h4{
            font-size: 36px;
        }
        h1{
            font-size: 100px;
        }
    }
    @media (max-width: 480px) {
        .soliBody {
          padding: 10px;
        }
        .switch-tab {
          flex-direction: column;
          align-items: center;
        }
        .switch-tab span {
          margin: 10px 0;
        }
      }
      
}
      
      



