.solicDoc{
    text-align: left;
    padding: 50px 100px;
    .solicDocument{
        @media (max-width: 768px) {
            button{
                margin-right: auto;
                margin-left: 10px;
            }
        }
        h4{
            text-align: center;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: #D0021B;
        }
        .solicTable{
            padding: 20px 0px;
            .search form{
                margin: 0px 0px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media (max-width: 768px) {
                .search form{
                    flex-direction: column;
                }
            }
        }
    }
}
.rango-fechas{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    .react-datepicker-wrapper {
        input {
          width: 100%;
        }
      }
}
.solicTable li{
    width: 30%;
    position: relative;
    list-style: none;
    select{
        width: 100%;
        appearance: none;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        margin-top: 4vh;
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    input{
        width: 100%;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        &::placeholder{
            font-family: 'SegoeUI';
            font-size: 14px;
            line-height: 16px;
            color: #A19F9D;
        }
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    svg{
        position: absolute;
        top: 10px;
        right: 10px;
        left: auto;
    }
}

.solicTable li.filter{
    svg{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.solicTable .pageNum li svg{
    position: static;
}

select{
    width: 100%;
    appearance: none;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
input{
    width: 100%;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &::placeholder{
        font-family: 'SegoeUI';
        font-size: 14px;
        line-height: 16px;
        color: #A19F9D;
    }
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
.soltable li:nth-child(2){
    svg{
        left: auto;
        right: 15px;
        top: 10px;
    }
}
@media (max-width: 768px) {
    .solicTable li{
        width: 100%;
        margin-bottom: 10px;
    }
}
.soltable .responsiveAuto ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    margin: 0;
    list-style-type: none;
    
    li {
        display: flex;
        align-items: center;
        justify-content: center; 
        width: calc(100% / 10); 
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -0.08px;
        color: #343741;
        border-bottom: 1px solid #D3DAE6;
        padding: 10px 15px;
        box-sizing: border-box;

        span {
            display: inline-flex;
            height: 12px;
            width: 14px;
            align-items: center;
            justify-content: center;
            padding: 7px 7px; 
            font-size: 12px; 
            background-color: var(--primary-color); /* Usa el color de fondo deseado */
            color: var(--fourth-color); /* Color del texto */
            border: none;
            border-radius: 70px; /* Mantén la forma redondeada */
            font-weight: bold; /* Asegúrate de que el texto esté en negrita */
            text-align: center;
            box-sizing: border-box; /* Asegura que el padding no afecte el tamaño del botón */
            margin-right: 5px; /* Elimina el margen si lo hay */
        }
        button {
            background-color: #D0021B;
            color: #fff;
            border: none;
            border-radius: 20px;
            padding: 5px 10px;
            cursor: pointer;
            font-weight: bold;
         }
    }
    li.firstItem {
        color: #006BB4;
        text-align: center;
    }
    li.listHeader {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.24px;
        color: #1A1C21;
    }
}

.solicTable .delete-box{
    position: relative;
    svg{
        position: absolute;
        top: 0px;
        left: 0px;
    }
}
@media (max-width: 768px) {
    .soltable .responsiveAuto{
        width: 100%;;
    }
}
@media (max-width: 768px) {
    .solicDoc{
        padding: 20px 10px;
        margin-top: 10px;
        .solicDocument{
            margin-top: 10px;
            width: 100%;
            margin-bottom: 10px;
            text-align: center;
        }
    }
}