.App {
    text-align: center;
}
.marginC{
    margin:0;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: var(--fourth-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--fourth-color);
}

.App-link {
    color: var(--link-color);
}

h4.redhead {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: var(--primary-color);
    margin: 10px 10px;
    text-transform: uppercase;
}

p.redhead {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    margin: 10px 10px;
    text-transform: uppercase;
}

p.subHead {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

h4.redhead {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #D0021B;
    margin-bottom: 10px;
}

p.subHead {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
