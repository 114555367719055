.detailCreditR{
    padding: 30px;
    font-size: 14px;
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0px 15px;
        h2{
            margin: 0px auto !important;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: var(--primary-color);
        }
    }
    p.presenta{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
        color: var(--fourth-color);
        border: none;
        border-radius: 38px;
        background-color: #2FD32B;
        padding: 5px 15px;
        text-align: center;
    }
    .selectOp{
        display: flex;
        margin: 20px 0px 10px;
        // select{

        // }
    }
    ul.submit{
        display: flex;
        justify-content: space-between;
        button{
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: var(--fourth-color);
            background: #979797;
            border-radius: 20px;
            padding: 5px 20px;
            border: none;
        }
    }
    @media (max-width: 768px) {
        .header{
            flex-direction: column;
            button{
                margin-bottom: 20px;
                margin-right: auto;
            }
            h2{
                margin-bottom: 10px;
            }
        }
        p.presenta{
            margin-bottom: 10px;
            font-size: 14px;
            width: 100%;
        }
    }
}