.UserDoc{
    text-align: left;
    padding: 50px;
    .UserDocument{
        @media (max-width: 768px) {
            button{
                margin-right: auto;
                margin-left: 10px;
            }
        }
        h4{
            text-align: center;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: var(--primary-color);
        }
        .UserTable{
            padding: 20px 0px;
            display: flex;
            .first-part{
                width: 60%;
                margin-right: 20px;
                .cat-head {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 20px;
                    .foto {
                        width: 20%;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        border: 1px solid #000000;
                        border-radius: 9px;
                        img {
                            margin-bottom: 10px;
                            width: 70%;
                        }
                        p{
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 113.5%;
                            text-align: center;
                            color: #7C8B87;    
                        }
                    }
                    .desc{
                        width: 80%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        h5{
                            margin-bottom: 5px;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 113.5%;
                            color: var(--dark-color);                            
                        }
                        h6{
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 113.5%;
                            color: var(--primary-color);
                            cursor: pointer;  
                        }
                    }
                }
                .userAdd {
                    display: flex;
                    justify-content: flex-start;
                    button {
                        width: 30%;
                        margin-right: 40px;
                    }
                }
                @media screen and (max-width: 768px) {
                    .userAdd {
                        flex-direction: column;
                        button {
                            width: 100%;
                            margin-right: 0px;
                        }
                    }
                }
            }
            .second-part{
                width: 40%;
                .search {
                    form {
                        button {
                            cursor: pointer;
                        }
                        li {
                            input {
                                border-radius: 20px;
                            }
                        }
                    }
                }
                .image {
                    max-width: 100%;
                    img {
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
                }
            }
            @media screen and (max-width: 768px) {
                .first-part{
                    width: 100%;
                    margin-right: 0px;
                    order: 1;
                    .cat-head{
                        .foto {
                            width: 30%;
                        }
                        .desc {
                            width: 70%;
                        }
                    }
                }
                .second-part{
                    order: 0;
                    width: 100%;
                    .image  {
                        display: none;
                    }
                }
            }
            .search form{
                margin: 0px 0px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media (max-width: 768px) {
                .search form{
                    flex-direction: column;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .UserTable{
                flex-direction: column;
            }
        }
    }
}
.UserTable li{
    width: 45%;
    position: relative;
    list-style: none;
    select{
        width: 100%;
        appearance: none;
        padding-left: 15px;
        border-radius: 20px;
        height: 30px;
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    input{
        width: 100%;
        padding-left: 30px;
        border-radius: 0px;
        height: 30px;    
        border: 1px solid var(--dark-color);
        color: var(--dark-color);
        &::placeholder{
            font-family: 'SegoeUI';
            font-size: 14px;
            line-height: 16px;
            color: var(--dark-color);
        }
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    svg{
        position: absolute;
        top: 10px;
        left: 10px;
    }
}
.UserTable li.filter{
    svg{
        position: absolute;
        top: 10px;
        right: 10px;
        left: auto;
    }
}
.UserTable .pageNum li svg{
    position: static;
}

.UserDoc .UserDocument .userAdd{
    display: flex;
    justify-content: space-between;
    button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        border-radius: 20px;
        padding: 5px 15px;
        border: none;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    @media screen and (max-width: 768px) {
        button{
            width: 100%;
        }
    }
}
@media screen and (max-width: 768px) {
    .userAdd{
        flex-direction: column;
    }
}

select{
    width: 100%;
    appearance: none;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
input{
    width: 100%;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &::placeholder{
        font-family: 'SegoeUI';
        font-size: 14px;
        line-height: 16px;
        color: #A19F9D;
    }
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
@media (max-width: 768px) {
    .UserTable li{
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 768px) {
    .UserDoc{
        padding: 20px 10px;
        margin-top: 10px;
        .UserDocument{
            margin-top: 60px;
        }
    }
}

.search-result{
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
            height: 30px;
        }
        li.name{
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.08px;
            color: #006BB4;
            border-bottom: 1px solid #D3DAE6;
        }
        li.action{
            color: var(--fourth-color);
            button{
                padding: 5px 25px;
                border: none;
                border-radius: 20px;
            }
        }
        margin-bottom: 30px;
    }
    .search-info{
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            li{
                margin-left: 15px;
                display: flex;
                width: 100%;
                h2{
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(black-color);
                    margin-right: 15px;           
                }
                p{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--black-color);               
                }
            }
        }
        
        @media screen and (max-width: 768px) {
            ul{
                flex-direction: column;
                li{
                    margin-bottom: 5px;
                }
            }
        }
    }
}