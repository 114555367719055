.detailInfo{
    padding: 30px;
    .header{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 5px 0px 15px;
        h2{
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: var(--primary-color);
        }
        p{
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: var(--black-color);
        }
        
    }
    @media (max-width: 768px) {
        .header{
            flex-direction: column;
            button{
                margin-bottom: 20px;
                margin-right: auto;
            }
            h2{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 10px;
                font-size: 20px;
                width: 100%;
            }
        }
    }
    .selectOp{
        display: flex;
        margin: 10px 0px;
        align-items: center;
        ul{
            display: flex;
            flex-direction: column;
            margin: 5px 20px;
            text-align: left;
            width: 20%;
            li{
                width: 100%;
                position: relative;
            }
            li:nth-child(2) svg {
                position: absolute;
                left: auto;
                right: 15px;
                top: 10px;
            }
            label{
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: var(--black-color);
                margin-bottom: 5px;
            }
        }
    }
    @media (max-width: 768px) {
        .selectOp{
            flex-direction: column;
            ul{
                width: 100%;
            }
        }
    }
    .body{
        display: none;
        .mainDetail{
            padding: 0px 20px;
            width: 100%;
            .box{
                width: 100%;
                padding: 0px 10px;
                border: none;
                border-radius: 20px;
                margin-bottom: 20px;
                ul{
                    padding-inline-start:0px;
                    margin: 10px 5px;
                }
                @media (max-width: 768px) {
                    ul{
                        margin: 10px 0px;
                    }
                }
                .title{
                    display: flex;
                    margin-bottom: 10px;
                    position: relative;
                    li{
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 19px;
                        color: var(--primary-color);
                        width: auto;
                        &:first-child{
                            margin-right: 20px;
                        }
                    }
                    svg{
                        margin-left: 5px;
                    }
                    .edit{
                        position: absolute;
                        top: 0px;
                        right: 10px;
                    }
                }
                .redBox{
                    border-radius: 44px;
                    border: 1px solid var(--primary-color);
                    padding: 10px 15px;
                    margin: 20px 10px;
                }
                .sub-box{
                    text-align: left;
                    h3{
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: var(--dark-color);
                        margin: 10px 30px;
                        padding-left: 2rem;  
                    }
                    h5{
                        margin: 10px;
                        padding-left: 2rem;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: var(--dark-color);
                    }
                    ul{
                        padding-inline-start:0px;
                        display: flex;
                        margin: 10px;
                        .redic{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            margin-right: 70px;
                            .title{
                                font-size: 24px;
                                line-height: 30px;
                                color: var(--primary-color);
                            }
                        }
                        @media (max-width: 768px) {
                            .redic{
                                margin-right: 0px;
                            }
                        }
                        .mapping{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 15px;
                            width: 100%;
                            .check{
                                margin-bottom: 10px;
                                padding: 15px;
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;
                                border-right: 1px solid #1B9543;
                                svg{
                                    margin-bottom: 5px;
                                }
                                p{
                                    font-size: 12px;
                                    line-height: 16px;
                                    color: #1B9543;
                                    margin-bottom: 15px;
                                }
                                h6{
                                    font-weight: 700;
                                    font-size: 12px;
                                    line-height: 16px;
                                    text-align: center;
                                    color: var(--black-color);    
                                }
                            }
                            .score{
                                padding: 15px;  
                            }
                        }
                        @media (max-width: 768px) {
                            .mapping{
                                flex-direction: column;
                                .check{
                                    border-right: none;
                                    border-bottom: 1px solid #1B9543;
                                }
                            }
                        }
                        li{
                            margin: 5px 20px;
                            text-align: left;
                            label{
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 18px;
                                color: var(--black-color);
                                margin-bottom: 5px;
                            }
                            p{
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                color: var(--black-color);
                                margin-left: 5px;
                                input{
                                    border: 1px solid #A19F9D;
                                    padding-left: 10px;
                                    height: 30px;
                                    &:focus{
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                    ul.w-100{
                        padding-inline-start:0px;
                        li{
                            width: 100%;
                        }
                    }
                    ul.submit{
                        padding-inline-start:0px;
                        display: flex;
                        justify-content: space-between;
                        button{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            color: var(--fourth-color);
                            background: #979797;
                            border-radius: 20px;
                            padding: 5px 20px;
                            border: none;
                        }
                    }
                    @media (max-width: 768px) {
                        ul{
                            flex-direction: column;
                            li{
                                width: 80%;
                            }
                        }
                        ul.submit{
                            flex-direction: row;
                        }
                    }
                    .pillInfo{
                        display: flex;
                        margin: 15px 0px;
                        padding: 0px 2rem;
                        justify-content: space-between;
                        p{
                            display: inline-block;
                            font-size: small;
                            line-height: 24px;
                            color: var(--black-color); 
                            margin: 10px 0px;                       
                        }
                    }
                    @media (max-width: 768px) {
                        .pillInfo{
                            flex-direction: column;
                        }
                    }
                }
                .submit{
                    display: flex;
                    justify-content: flex-end;
                    button{
                        padding: 5px 15px;
                        background: var(--primary-color);
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                        width: 180px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .submitStartG{
                    display: flex;
                    justify-content: flex-start;
                    margin: 10px 0px;
                    padding-left: 2rem;
                    button{
                        padding: 8px 15px;
                        background: #1B9543;
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .submitDisable{
                    display: flex;
                    justify-content: flex-end;
                    margin: 10px 0px;
                    padding-left: 2rem;
                    button{
                        padding: 8px 15px;
                        background: #4A4A4A;
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .submitStartR{
                    display: flex;
                    justify-content: flex-start;
                    margin: 10px 0px;
                    padding-left: 2rem;
                    button{
                        padding: 8px 15px;
                        background: var(--primary-color);
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .submitDis{
                    display: flex;
                    justify-content: flex-end;
                    button{
                        padding: 5px 15px;
                        background: #4A4A4A;
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                        width: 180px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .submitHide{
                    display: flex;
                    justify-content: flex-end;
                    button{
                        padding: 5px 15px;
                        background: #979797;
                        color: var(--fourth-color);
                        border: none;
                        border-radius: 182px;
                        margin-right: 30px;
                        width: 180px;
                    }
                    @media (max-width: 768px) {
                        button{
                            margin-right: 0px;
                            width: 100%;
                        }
                    }
                }
                .documents{
                    display: flex;
                    margin: 10px 0px;
                    padding: 0px 30px;
                    .redBox{
                        border-radius: 5px;
                        border: 1px solid var(--primary-color);
                        padding: 10px 15px;
                        margin: 20px 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        p{
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: var(--primary-color); 
                            margin: 5px;  
                            width: 50%; 
                        }
                    }
                }
                .table{
                    width: 100%;
                    margin: 0 auto;
                }
                @media (max-width: 768px) {
                    .documents{
                        flex-direction: column;
                    }
                    .table{
                        width: 100%;
                    }
                    .submitStartG{
                        padding-left: 0;
                    }
                    .submitStartR{
                        padding-left: 0;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .mainDetail{
                width: 100%;
                padding: 0px;
                margin: 15px 0px;
            }
        }
        
        @media (max-width: 768px) {
            .detailCredit{
                padding: 0px 15px 15px 15px;
            }
        }
    }
    @media (max-width: 768px) {
        .body{
            flex-direction: column;
        }
    }
}

